<template>
  <div class="h-100 w-100">
    <BListCard
      :searchActive="false"
      :newAddActive="false"
      :totalActive="false"
      :show="show"
      :title="`${selectMenuItem}`"
      :total="0"
    >
      <template v-slot:leftBody>
        <ul class="menu-list-group">
          <li class="menu-list-group-item" v-for="(item, index) in menus" :key="index">
            <router-link :to="{ name: item.route }">
              <i v-if="item.icon" :class="`fad fa-${item.icon}`" />
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </template>
      <template v-slot:rightBody>
        <div>
          <transition :name="transitionEffect" mode="out-in">
            <router-view @show="show = $event" />
          </transition>
        </div>
      </template>
    </BListCard>
  </div>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';
import store from '@/store';
export default defineComponent({
  components: {
    BListCard,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();
    expo.show = ref(false);
    expo.menus = ref([
      {
        title: 'Menü',
        route: 'menu',
        icon: 'bars',
      },
      {
        title: 'Site Seo',
        route: 'seo',
        icon: 'font-case',
      },
      {
        title: 'Dil Ayarları',
        route: 'dil',
        icon: 'language',
      },
      {
        title: 'Uyelik Ayarları',
        route: 'uyelik',
        icon: 'users',
      },
      {
        title: 'Destek Ayarları',
        route: 'destek',
        icon: 'user-headset',
      },
      {
        title: 'Kur Ayarları',
        route: 'kur',
        icon: 'exchange',
      },
      {
        title: 'Mail Ayarları',
        route: 'mail',
        icon: 'envelope',
      },
      {
        title: 'Sms Ayarları',
        route: 'sms',
        icon: 'sms',
      },
      {
        title: 'Sosyal Medya',
        route: 'sosyal',
        icon: 'share-alt-square',
      },
      {
        title: 'Logo & Favicon',
        route: 'logo',
        icon: 'images',
      },
      {
        title: 'İletişim Bilgileri',
        route: 'iletisim',
        icon: 'id-card-alt',
      },
      {
        title: 'Firma Bilgileri',
        route: 'firma',
        icon: 'building',
      },
      {
        title: 'Ödeme Sistemi',
        route: 'odeme-sistemi',
        icon: 'credit-card',
      },
      {
        title: 'Banka Bilgileri',
        route: 'banka',
        icon: 'university',
      },
      {
        title: 'Diğer Ayarlar',
        route: 'diger',
        icon: 'user-cowboy',
      },
      {
        title: 'Yedekleme',
        route: 'yedekleme',
        icon: 'cloud-download-alt',
      },
    ]);

    expo.transitionEffect = computed(() => store.getters.getTransitionEffect);
    expo.selectMenuItem = ref(expo.menus.value.find((x) => x.route == route.value.name).title);

    watch(route, (val) => {
      expo.selectMenuItem.value = expo.menus.value.find((x) => x.route == val.name).title;
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.menu-list-group {
  padding: 10px;
  border-radius: 0;
  .menu-list-group-item {
    position: relative;
    border: none;
    padding: 0px;
    margin: 5px;
    display: block;
    justify-content: space-between;
    align-items: center;
    a {
      color: #4d4d4d;
      font-size: 18px;
      font-weight: 500;
      display: block;
      padding: 15px 10px;
      transition: all 0.2s ease-in-out;
      i {
        padding-right: 10px;
      }
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
      &.router-link-active {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
